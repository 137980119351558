
import $app from '@/plugins/modules'
import { System } from '@/modules/system'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IAuditRecord, IPatient } from '../types';

@Component
export default class CustomerAuditTab extends Vue {
  @Prop({ default: () => { } })
  readonly patient: IPatient | undefined;

  system = $app.module(System);

  ipp = $app.module(System).$store.getCurrentUser.rowsPerPage;
  showFilter = false;
  visitVisible = false;
  visitSelected: number | null = null;

  filterFrom = new Date().addMonth(-1).native(false);
  filterTo = new Date().addMonth(1).native(false);

  get headers() {
    return [
      {
        text: '',
        value: 'id',
        align: 'left',
        sortable: false
      },
      {
        text: $app.i18n('system.Date'),
        value: 'date',
        align: 'left'
      },
      {
        text: $app.i18n('system.User'),
        value: 'userName',
        align: 'left'
      },
      {
        text: $app.i18n('system.type'),
        value: 'objectType',
        align: 'left'
      },
      {
        text: $app.i18n('system.Id'),
        value: 'objectId',
        align: 'left'
      },
      {
        text: $app.i18n('admin.system.MainData'),
        value: 'data',
        align: 'left'
      }
    ];
  }

  get records(): Array<IAuditRecord> {
    const from = new Date(this.filterFrom);
    const to = new Date(this.filterTo);

    return !this.patient || !this.patient.audit
      ? []
      : this.patient.audit.filter(r => {
        if (this.showFilter) {
          return new Date(r.date).getTime() >= from.getTime() && new Date(r.date).getTime() < to.getTime();
        } else {
          return new Date(r.date).getTime() >= new Date().addDay(-1).getTime() && new Date(r.date).getTime() < new Date().getTime()
        }
      });
  }
}
